import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store"; 
const authModule = getModule(AuthModule, store); 

axios.interceptors.request.use(
  async (config) => {
    const token = authModule.token;
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status) {
      switch (error.response.status) { 
        case 401:
          if (router.currentRoute.path !== "/login") {
            authModule.logout();
            router.push({ name: "login" });
          }
          break; 
      }
    }  
    return Promise.reject(error);
  }
);

export abstract class ApiService {
  constructor() {
    axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
  } 

  getAll<ResponseType>(
    uri: string,
    params: AxiosRequestConfig = null
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.get<ResponseType>(uri, params);
  }

  post<BodyType, ResponseType>(
    uri: string,
    params: BodyType,
    options: AxiosRequestConfig = null
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.post<ResponseType>(`${uri}`, params, options);
  }
}
